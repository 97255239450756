import React, {useState} from 'react';
import classes from './TransactionHistoryTable.module.css';
import {useTranslation} from "react-i18next";
import moment from "moment-jalaali";
import Date from "../../../../../components/Date/Date";
import {BN, ratePrint} from "../../../../../utils/utils";
import useGlobal from "../../../../../Hooks/useGlobal";

const TransactionHistoryTable = ({data, index, offset}) => {

    const {t} = useTranslation();
    const {global} = useGlobal();

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`width-90 py-2 columns jc-between ai-center ${classes.container}`} onClick={()=>data?.description && setIsOpen(prevState => !prevState)}>
            {/* <div className={`${classes.circle} width-23 flex jc-start ai-center`}
                 style={{backgroundImage: `url("${data?.image_url}")`}}
            >

            </div>*/}

            <div className={`width-100 column jc-between ai-center ${classes.rectangle}`}>

                <div className={`width-100 row jc-between ai-center`}>
                    <span><Date date={data?.date}/></span>
                    <span className={`row jc-center ai-center`}>{t("TransactionCategory."+ data?.category)}</span>

                    {/*<span className={`row jc-center ai-center`}>{moment(data?.date).format("HH:mm:ss")}</span>*/}
                </div>

                <div className={`width-100 row jc-between ai-center`}>
                    <span className={``}>{moment(data?.date).format("HH:mm:ss")}</span>
                    <div className={`row jc-center ${ (data?.category !== "WITHDRAW_REQUEST" && data?.category !== "WITHDRAW_REJECT") && (data?.withdraw ? "text-red" : "text-green")}`} style={{alignItems:"baseline"}}>
                        <span>{data?.currency}</span>
                        <span className={`${classes.spacing}`}/>
                        <span className={`fs-03`}>{ ratePrint(data?.amount, global?.currencies[data?.currency]?.precision)}</span>

                        { (data?.category !== "WITHDRAW_REQUEST" && data?.category !== "WITHDRAW_REJECT") && <>
                            <span className={`${classes.spacing}`}/>
                            <span >{data?.withdraw ? "-" : "+"}</span>
                        </> }
                    </div>
                </div>



            </div>

            {isOpen && <div className={`width-90 column jc-start ai-start mt-2 ${classes.rectangle}`}>
                <span>{data?.description}</span>

            </div>}


        </div>
    );
};

export default TransactionHistoryTable;
