import React, {useState} from 'react';
import classes from './RecentTxHistoryCard.module.css'
import {useTranslation} from "react-i18next";
import Date from "../../../../../../components/Date/Date";
import moment from "moment-jalaali";
import {ratePrint} from "../../../../../../utils/utils";
import useGlobal from "../../../../../../Hooks/useGlobal";

const RecentTxHistoryCard = ({data, index}) => {

    const {t} = useTranslation();

    const {global} = useGlobal();

    const [isOpen, setIsOpen] = useState(false);


    const colorHandler = (withdraw, category) => {
        if (category !== "WITHDRAW_REQUEST") return ""
        else {
            if (withdraw) return "text-red"
            if (!withdraw) return "text-green"
        }
    }


    /*const errorMsg = (code) => {
        switch (code) {
            case "file-too-large":
                return t("ImageInput.fileTooLarge");
            case "file-invalid-type":
                return t("ImageInput.fileInvalidType");
            case "too-many-files":
                return t("ImageInput.tooManyFiles");
            default:
                return t("ImageInput.errorMsgDefault");
        }
    };*/


    return (
        <div className={`width-90 py-2 columns jc-between ai-center ${classes.container}`}>
            {/* <div className={`${classes.circle} width-23 flex jc-start ai-center`}
                 style={{backgroundImage: `url("${data?.image_url}")`}}

            >

            </div>*/}

            {/*
            (data?.category !== "DEPOSIT") || (data?.category !== "DEPOSIT_MANUALLY") &&
            */}

            <div className={`width-100 column jc-between ai-center ${classes.rectangle}`} onClick={()=> data?.description && setIsOpen(prevState => !prevState)}>

                <div className={`width-100 row jc-between ai-center`}>
                    <span><Date date={data?.date}/></span>
                    <span className={`row jc-center ai-center`}>{t("TransactionCategory."+ data?.category)}</span>
                </div>

                <div className={`width-100 row jc-between ai-center`}>
                    <span className={``}>{moment(data?.date).format("HH:mm:ss")}</span>
                    <div className={`row jc-center ${ (data?.category !== "WITHDRAW_REQUEST" && data?.category !== "WITHDRAW_REJECT") && (data?.withdraw ? "text-red" : "text-green")}`} style={{alignItems:"baseline"}}>
                        <span>{data?.currency}</span>
                        <span className={`${classes.spacing}`}/>
                        <span className={`fs-03`}>{ratePrint(data?.amount, global?.currencies[data?.currency]?.precision)}</span>

                        { (data?.category !== "WITHDRAW_REQUEST" && data?.category !== "WITHDRAW_REJECT") && <>
                            <span className={`${classes.spacing}`}/>
                            <span >{data?.withdraw ? "-" : "+"}</span>
                        </> }
                    </div>
                </div>



            </div>

            {isOpen && <div className={`width-100 column jc-start ai-start mt-2 ${classes.rectangle}`}>
                <span>{data?.description}</span>

                {/*<div className={`row width-100`}>
                    <span className={`${classes.ref}`} onClick={()=>window.open(`${scanner(data?.currency)}/${data?.ref}`)}>{(data?.ref)}</span>
                    <Icons
                        iconName="icon-icons8-external-link fs-04"
                        iconClass={`cursor-pointer`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setFiles([]);
                            props.onchange("");
                        }}
                    />


                </div>*/}


            </div>}


        </div>
    );
};

export default RecentTxHistoryCard;
