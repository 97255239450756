import React from 'react';
import classes from './Guide.module.css'
import Sample from "../Sample/Sample";
import Button from "../../../../../../components/Button/Button";
import ScrollBar from "../../../../../../components/ScrollBar";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";

const Guide = () => {


    const {t} = useTranslation();
    const navigate = useNavigate();

    const acceptFormSampleImg = window.env.REACT_APP_FORM_IMAGE_URL
    const selfieSampleImg = window.env.REACT_APP_SELFIE_IMAGE_URL
    const idCardSampleImg = window.env.REACT_APP_IDCARD_IMAGE_URL

    const sampleData = [
        {title: "acceptFormSample", src: acceptFormSampleImg, id:"1"},
        {title: "selfieSample", src: selfieSampleImg, id:"2"},
        {title: "idCardSample", src: idCardSampleImg, id:"3"},
    ]

    const submit = async (e) => {
        e.preventDefault();
        //if (!isFormValid()) return false;
        return navigate(RoutesName.UploadImages)
    }
    return (
        <ScrollBar>
            <form onSubmit={(e)=>submit(e)} className={`${classes.container} column jc-between ai-center py-3 px-5`}>
                <div className={`width-86 column jc-center ai-center`}>
                    <div className={`rounded-8 flex jc-center ai-start ${classes.noteBox} px-5 py-2 mt-2 mb-3`}>
                        <span className={``}>{t("Authentication.content")}</span>
                    </div>
                    {sampleData.map((data , index) => <Sample data={data} index={index} key={index} />)}
                </div>
                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-2`}
                    buttonTitle={t('start')}
                />
            </form>
        </ScrollBar>
    );
};

export default Guide;
